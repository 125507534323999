export default function usePacesetter() {
  const { questions } = storeToRefs(usePacesetterStore())

  const quizQuestions = [
    {
      type: 'mc',
      category: 'music',
      question:
        'Billy Corgan is the lead singer of which alternative rock band?',
      options: [
        'The Smashing Pumpkins',
        'The Beatles',
        'Backstreet Boys',
        'Westlife',
      ],
      answered: false,
    },
    {
      type: 'image',
      category: 'celebrity',
      question: 'Who is the celebrity?',
      image: 'https://nairametrics.com/wp-content/uploads/2021/12/Wizkid.jpg',
      options: ['Wizkid', 'Davido', 'Burna Boy', 'Asake'],
      answered: false,
    },
    {
      type: 'mc',
      category: 'music',
      question:
        'Billy Corgan is the lead singer of which alternative rock band?',
      options: [
        'The Smashing Pumpkins',
        'The Beatles',
        'Backstreet Boys',
        'Westlife',
      ],
      answered: false,
    },
    {
      type: 'mc',
      category: 'Maths',
      question: '2 + 2 = ?',
      options: ['5', '5', '3', '10'],
      answered: false,
    },
  ]

  function fetchQuizQuestions() {
    questions.value = quizQuestions
  }

  return {
    questions,
    fetchQuizQuestions,
  }
}
