<template>
  <div>
    <div class="sticky top-0 z-30 w-full">
      <LazyNetworkStatus />
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
const { fetchQuizQuestions } = usePacesetter()

onMounted(() => {
  fetchQuizQuestions()
})
</script>
